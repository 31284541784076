<template>
    <div>
        <div
            v-if="menuIndex"
            id="menu-lv1"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            aria-labelledby="bankAccount"
            aria-hidden="true"
            style="display: none;"
        >
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title bold">Thêm Dịch Vụ</h4>
                    </div>
                    <form @submit.stop.prevent="updateMenus">
                        <div class="modal-body">
                            <div class="form-group">
                                <label class="bold red">Tên Dịch Vụ:</label>
                                <input type="text" v-model="menuIndex.name" class="form-control" />
                            </div>
                            <div class="form-group">
                                <label class="bold"
                                    >Icon
                                    <a
                                        target="_blank"
                                        class="font-italic"
                                        href="https://fontawesome.com/icons?d=gallery&q=menu"
                                        >lấy tại đây</a
                                    >
                                    - Ví dụ:
                                    <code data-balloon="Copy HTML" data-balloon-pos="down" class="dib f2 hover-orange7"
                                        ><span class="o-40">&lt;i class="</span>far fa-thumbs-up<span class="o-40"
                                            >"&gt;&lt;/i&gt;</span
                                        ></code
                                    >
                                </label>
                                <div class="input-group">
                                    <input
                                        v-model="menuIndex.icon"
                                        placeholder="Nhập icon bao gồm thẻ i như ví dụ trên"
                                        type="text"
                                        class="form-control select-right"
                                        name="example-email"
                                        min="1"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text" v-html="menuIndex.icon"> </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-auto">
                                    <label class="bold red">Thêm gói dịch vụ:</label>
                                </div>
                                <div class="col-md">
                                    <div class="row form-group mb-2">
                                        <div class="col-md-auto col-12">
                                            <h6 class="bold mb-0">Tên gói:</h6>
                                        </div>
                                        <div class="col-md col-12">
                                            <input type="text" v-model="name" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <div class="col-md-auto col-12">
                                            <h6 class="bold mb-0">Giá gói:</h6>
                                        </div>
                                        <div class="col-md col-12">
                                            <input type="number" v-model="prices" class="form-control" />
                                            <button
                                                @click="addPackage()"
                                                type="button"
                                                class="btn btn-dark-blue btn-xs mt-3 px-3"
                                            >
                                                Thêm gói
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="menuIndex.package && menuIndex.package.length > 0" class="form-group mb-0">
                                <datatable
                                    title="Danh sách gói"
                                    stt="true"
                                    :columns="columnsTableLogs"
                                    :rows="menuIndex.package"
                                >
                                    <th slot="thead-tr" class="text-center" style="vertical-align: middle">Thao Tác</th>
                                    <td
                                        slot="tbody-tr"
                                        slot-scope="props"
                                        class="text-center"
                                        style="vertical-align: middle"
                                    >
                                        <button
                                            @click="removePackage(props.row)"
                                            type="button"
                                            class="btn btn-danger btn-sm ml-2"
                                        >
                                            Xóa
                                        </button>
                                    </td>
                                </datatable>
                            </div>
                            <div class="form-group">
                                <label class="bold red">Hướng dẫn sử dụng:</label>
                                <textarea
                                    class="form-control"
                                    rows="5"
                                    v-model="menuIndex.notes"
                                    placeholder="Nhập hướng dẫn sử dụng khi order"
                                ></textarea>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">
                                Hủy bỏ
                            </button>
                            <button type="submit" :class="['btn btn-dark-blue waves-effect waves-light']">
                                Cập Nhật
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { createMenus, updateMenus, removePackage } from "../../../api/admin"
import datatable from "../../../components/datatable/datatable"
import { catchError } from "../../../helpers"
export default {
    name: "Menu",
    components: {
        datatable
    },
    props: {
        isUpdate: Boolean,
        menuIndex: Object
    },
    data() {
        return {
            name: "Gói 1",
            prices: 1000,
            columnsTableLogs: [
                { label: "Tên gói", field: "name" },
                { label: "Giá gói", field: "prices", numeric: true }
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        }
    },
    methods: {
        async addPackage() {
            if (!this.name) {
                this.$toastr.error("Chưa nhập tên gói")
                return
            }
            if (this.isUpdate) {
                let add = {
                    name: this.name,
                    prices: this.prices
                }
                this.menuIndex.package.push(add)
                let postData = {
                    name: this.menuIndex.name,
                    notes: this.menuIndex.notes,
                    package: this.menuIndex.package,
                    id: this.menuIndex.id
                }
                let data = await updateMenus(postData)
                if (data && data.status === 200 && data.success) {
                    this.$emit("reload")
                    this.$toastr.success("Thành Công", data.message, "success")
                } else this.$swal("Lỗi", catchError(data), "error")
            } else {
                let data = {
                    name: this.name,
                    prices: this.prices
                }
                this.menuIndex.package.push(data)
            }
        },
        async removePackage(menu) {
            if (this.isUpdate) {
                let data = await removePackage(menu.id)
                if (data && data.status === 200 && data.success) {
                    this.$toastr.success("Thành Công", data.message, "success")
                    let index = this.menuIndex.package.indexOf(menu)
                    this.menuIndex.package.splice(index, 1)
                } else this.$swal("Lỗi", catchError(data), "error")
            } else {
                let index = this.menuIndex.package.indexOf(menu)
                this.menuIndex.package.splice(index, 1)
            }
        },
        async updateMenus() {
            let data = null
            if (this.isUpdate) {
                let postData = {
                    name: this.menuIndex.name,
                    notes: this.menuIndex.notes,
                    package: this.menuIndex.package,
                    icon: this.menuIndex.icon,
                    id: this.menuIndex.id
                }
                data = await updateMenus(postData)
            } else {
                let postData = {
                    name: this.menuIndex.name,
                    notes: this.menuIndex.notes,
                    icon: this.menuIndex.icon,
                    package: this.menuIndex.package
                }
                data = await createMenus(postData)
            }
            if (data && data.status === 200 && data.success) {
                this.$emit("reload")
                $("#menu-lv1").modal("hide")
                this.$swal("Thành Công", data.message, "success")
            } else this.$swal("Lỗi", catchError(data), "error")
        }
    }
}
</script>
