<template>
    <div>
        <h4 class="bold text-uppercase">Cài đặt Menus</h4>
        <div class="row mt-4">
            <div class="col-md-8 col-12">
                <div class="form-group row" v-for="(menu, index) in menus" :key="index">
                    <label class="col-sm-2 bold">Dịch vụ số: {{ index + 1 }}</label>
                    <div class="col-sm">
                        <div class="card card-table">
                            <div class="card-body">
                                <h6 class="bold">
                                    {{ menu.name }}
                                </h6>
                                <button
                                    @click="setUpdateMenus(menu)"
                                    type="button"
                                    class="btn btn-primary btn-xs mr-1 px-3"
                                    data-toggle="modal"
                                    href="#menu-lv1"
                                >
                                    Chi tiết
                                </button>
                                <button
                                    @click="removeMenus(menu)"
                                    type="button"
                                    class="btn btn-danger btn-xs mr-1 px-3"
                                >
                                    Xóa
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row align-items-center">
                    <label class="col-sm-2 col-form-label font-bold"></label>
                    <div class="col-sm">
                        <button
                            @click="addMenus()"
                            data-toggle="modal"
                            href="#menu-lv1"
                            class="btn form-control bold btn-dark-blue"
                            type="submit"
                        >
                            Thêm Dịch Vụ
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-3 mt-md-0 col-12">
                <div class="card card-info mt-3">
                    <div class="card-body">
                        <h6 class="font-bold text-center">VIDEO HƯỚNG DẪN</h6>
                        <iframe
                            class="mt-2"
                            width="100%"
                            height="300"
                            src="https://www.youtube.com/embed/m5bG5W19dkE"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                </div>
                <div class="card card-red h-100 mt-3">
                    <div class="card-body">
                        <h4>Lưu ý:</h4>
                        <h6>
                            - Các dịch vụ thêm mới sẽ tự động được thêm vào menu ngoài cùng của site cùng cấp với home,
                            nạp tiền,...
                        </h6>
                        <h6>
                            - Các dịch vụ sau khi thêm phải chờ hệ thống duyệt mới có thể hiển thị (trong giờ làm việc
                            hành chính 10h-18h) để tránh tạo các dịch vụ vi phạm pháp luật và cạnh tranh với các dịch vụ
                            có sẳn của hệ thống
                        </h6>
                    </div>
                </div>
            </div>
        </div>
        <menuslv1 @reload="reloadMenu" :isUpdate="isUpdate" :menuIndex="menuIndex"></menuslv1>
    </div>
</template>
<script>
import { getListMenus, removeMenus } from "../../../api/admin"
import { catchError } from "../../../helpers"
import menuslv1 from "./menus-lv1"
export default {
    name: "Menu",
    components: {
        menuslv1
    },
    data() {
        return {
            isUpdate: false,
            menus: [],
            menuIndex: {},
            menuDefault: {
                name: "",
                notes: "",
                package: []
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        }
    },
    created() {
        this.getListMenus()
    },
    methods: {
        addMenus() {
            this.menuIndex = Object.assign({}, this.menuDefault)
        },
        setUpdateMenus: function(menu) {
            this.menuIndex = Object.assign({}, menu)
            this.isUpdate = true
        },
        removeMenus: async function(menu) {
            let data = await removeMenus(menu.id)
            if (data.status === 200 && data.success) {
                this.getListMenus()
                this.$swal("Thành Công", data.message, "success")
            } else this.$swal("Lỗi", catchError(data), "error")
        },
        getListMenus: async function() {
            this.isUpdate = false
            let data = await getListMenus()
            if (data.status === 200 && data.success) {
                this.menus = data.data
            }
        },
        async reloadMenu() {
            let data = await getListMenus()
            if (data.status === 200 && data.success) {
                this.menus = data.data
                this.menuIndex = this.menus.find(item => item.id == this.menuIndex.id)
            }
        }
    }
}
</script>
